import { FC, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RHFAutocomplete } from 'src/components';
import { transactionPartieSides, transactionUserRelationship } from 'src/pages/transactions/constants';
import { selectTransactionParticipants } from 'src/redux/slices/transaction';
import { AuthorityOnFieldProps } from './types';

const AuthorityOnField: FC<AuthorityOnFieldProps> = ({ setError }) => {
	const { watch, setValue } = useFormContext();
	const [side, relationship, authorityOn] = watch(['side', 'relationship', 'authorityOn']);
	const participants = useSelector(selectTransactionParticipants);

	const shouldShowField =
		relationship === transactionUserRelationship.attorney.key ||
		relationship === transactionUserRelationship.executor.key ||
		relationship === transactionUserRelationship.proxy.key;

	const options = useMemo(() => {
		if (!shouldShowField) return [];

		const isSellSide = side === transactionPartieSides.sell.key;

		return (isSellSide ? participants.participants.sellers : participants.participants.buyers).map((p) => ({
			name: `${p.givenNames} ${p.lastNames}`,
			type: 'user',
			id: p.isPlaceholder ? p.placeholderId : p.uid,
		}));
	}, [participants, side, shouldShowField]);

	useEffect(() => {
		if (!shouldShowField) setValue('authorityOn', []);

		if (shouldShowField && !options.length)
			setError(
				side === transactionPartieSides.sell.key
					? 'No proprietors available to act on behalf of.\nPlease add proprietors before proceeding.'
					: 'No buyers available to act on behalf of.\nPlease add buyers before proceeding.',
			);
	}, [shouldShowField, side]);

	if (!shouldShowField) return null;

	return (
		<RHFAutocomplete
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			multiple
			onChange={(_, list) => setValue('authorityOn', list)}
			options={options}
			isOptionEqualToValue={(o, v) => o.id === v.id}
			getOptionLabel={(option) => option.name}
			value={authorityOn}
			label='Acting for'
			name='authorityOn'
			valueAttribute='key'
		/>
	);
};

export default AuthorityOnField;
